<template>
 <nav style="background-color: #1a1a1a;" class="fixed top-0 left-0 w-full z-50 shadow-lg" v-if="$store.state.loggedIn">
  <div style="background-color: #1a1a1a;" class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
   <a class="flex items-center space-x-3 rtl:space-x-reverse">
    <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white" @click="$router.push('/dashboard')">ComSaf DB</span>
   </a>
   <div class="hidden w-full md:block md:w-auto" id="navbar-default">
    <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
     <li>
      <p id="option" class="block py-2 px-3 text-white rounded md:text-blue-700 md:p-0 dark:text-white md:dark:text-white-500" aria-current="page" @click="$router.push('/dashboard')">Home</p>
     </li>
     <li>
      <p id="option" class="block py-2 px-3 text-white rounded md:text-blue-700 md:p-0 dark:text-white md:dark:text-white-500" aria-current="page" @click="$router.push('/users')">Users</p>
     </li>
     <li>
      <p id="option" class="block py-2 px-3 text-white rounded md:text-blue-700 md:p-0 dark:text-white md:dark:text-white-500" aria-current="page" @click="$router.push('/settings')">Settings</p>
     </li>
    </ul>
   </div>
  </div>
 </nav>
 <div id="page">
  <router-view/>
 </div>
</template>

<style>
 @tailwind base;
 @tailwind components;
 @tailwind utilities;
 
 * {
  overflow: scroll-y !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
 }

 *::-webkit-scrollbar {
  width: 0;
  height: 0;
 }

 #page {
  position: absolute;
  top: 4em;
  width: 100%;
  height: 94%;
  overflow-y: scroll;
  background-color: #161616;
 }

 input, select, textarea {
  background-color: #161616;
  color: white;
  border: 1px solid #3a3939 !important;
  outline: none !important;

 }

 #page::-webkit-scrollbar {
  width: 0;
  height: 0;
 }

 body {
  background-color: #161616;
 }

 #option:hover {
  color: rgb(156, 230, 255);
  cursor: pointer;
 }
</style>