import { createStore } from 'vuex'

export default createStore({
 state: {
  isLoggedIn: false, 
  loginData: [],
  currentReport: '',
 },
 mutations: {
 },
 actions: {
 },
 modules: {
 }
})
