import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import store from '@/store/index.js';

const routes = [
 {
  path: '/',
  name: 'login',
  component: Login
 },
 {
  path: '/dashboard',
  beforeEnter: isAuthenticated,
  component: () => import('../views/Dashboard.vue')
 },
 {
  path: '/report',
  name: 'Report',
  beforeEnter: isAuthenticated,
  component: () => import('../views/Report.vue')
 },
 {
  path: '/users',
  name: 'Users',
  beforeEnter: isAuthenticated,
  component: () => import('../views/Users.vue')
 },
 {
  path: '/settings',
  name: 'settings',
  beforeEnter: isAuthenticated,
  component: () => import('../views/Settings.vue')
 }
]

const router = createRouter({
 history: createWebHistory(process.env.BASE_URL),
 routes
})

function isAuthenticated(to, from, next) {
 if(store.state.loggedIn) {
  next();
 } else {
  next('/');
 }
}
 
export default router
