<template>
 <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm" style="margin-top: -10%;">
   <img class="mx-auto" src="../assets/logo.png" alt="PRISM logo" style="width: 250px;">
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
   <form class="space-y-6" @submit.prevent="login">
    <div>
     <label for="username" class="block text-sm font-medium leading-6 text-white">Username</label>
     <div class="mt-2">
      <input id="username" v-model="username" type="username" autocomplete="username" required class="block w-full p-2 text-white sm:text-sm sm:leading-6 border border-gray-600"/>
     </div>
    </div>

    <div>
     <div class="flex items-center justify-between">
      <label for="password" class="block text-sm font-medium leading-6 text-white">Password</label>
     </div>
     <div class="mt-2">
      <input id="password" v-model="password" type="password" autocomplete="current-password" required class="block w-full p-2 text-white sm:text-sm sm:leading-6 border border-gray-600"/>
     </div>
    </div>

    <div>
     <div class="flex items-center justify-between">
      <label for="2fa" class="block text-sm font-medium leading-6 text-white">Two Factor Authentication</label>
     </div>
     <div class="mt-2">
      <input id="2fa" v-model="tfa" type="2fa" autocomplete="current-2fa" required class="block w-full p-2 text-white sm:text-sm sm:leading-6 border border-gray-600"/>
     </div>
    </div>

    <div>
     <button @click="login()" class="flex w-full justify-center bg-blue-700 px-3 p-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Sign in</button>
    </div>

    <p v-if="message" class="text-red-500 text-center">{{ message }}</p>
   </form>
  </div>
 </div>
</template>

<script>
 import axios from 'axios'

 export default {
  name: 'Login',
  data() {
   return {
    username: '',
    password: '',
    tfa: '',
    message: ''
   }
  },
  methods: {
   login() {
    axios.post(process.env.VUE_APP_API_URL + '/login', {username: this.username, password: this.password, tfa: this.tfa}, {headers: {'api-key': process.env.VUE_APP_API_KEY}}).then(res => {
     var data = res.data
     if (data.success === true) {
       this.$store.state.loggedIn = true
       this.$store.state.loginData = data.data
       this.$router.push('/dashboard')
     } 
    }).catch(err => {
     var data = err.response.data
     if (data.success === false) {
      this.message = data.msg
     }
    })
   }
  }
 }
</script>

<style>
 input:focus {
  outline: none;
 }
</style>
